import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';

const Footer = () => {
    return (
        <Fragment>
            <footer className={styles.footerWrapper}>
                <div className={styles.footerContainer}>
                    <Grid>
                        <Row between={'md'}>
                            <Col lg={5} md={12} sm={12} xs={12}>
                                <div className={styles.footerContent}>
                                    <ul>
                                        <li>
                                            <Link to="/about">About us</Link>
                                        </li>
                                        <li>
                                            <Link to="/going-to-uni">Going to Uni?</Link>
                                        </li>
                                        <li>
                                            <Link to="/case-study">Case study</Link>
                                        </li>
                                        <li>
                                            <Link to="/become-a-pa">Become a PA</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog">Blogs</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQ</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact us</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/complaints">Complaints</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col  lg={3} xs={12}>
                                <Grid gutters={false}>
                                    <Row middle="xs">
                                        <Col xs={6} lg={12}>
                                            <img src="/images/Disability_Confident.png" alt='disability_confident_logo' className={styles.disabilityLogo}/>
                                        </Col>
                                        <Col xs={6} lg={12}>
                                            <div className={styles.communicationLogoContainer}>
                                                <img src="/images/communication_access.png" alt='communication_access_logo' className={styles.communicationLogo}/>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={12}>
                                            <div className={styles.timesLogoContainer}>
                                                <p>As seen in</p>
                                                <img src="/images/the_times.png" alt='the_times_logo' className={styles.timesLogo}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Col>
                            <Col lg={4} md={12} sm={12} xs={12}>
                                <div className={styles.social}>
                                    <div className={styles.footerImage}>
                                        <img src="/images/plants.svg" alt="plants" />
                                    </div>
                                    <div className={styles.socialMedia}>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/LiveYouUK "
                                                    title="LiveYou facebook"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'facebook']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.linkedin.com/company/liveyouuk"
                                                    title="LiveYou linkedIn"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="http://www.instagram.com/LiveYou_UK"
                                                    title="LiveYou instagram"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'instagram']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
