import React, { Fragment } from 'react';
import '@styles/styles.scss';
import styles from './styles.module.scss';
import SEO from '@components/SEO';
import { useLocation } from '@reach/router';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import Header from '@components/Navigation';
// import { faBars as faDuotoneBars } from '@fortawesome/pro-duotone-svg-icons';
// import { library } from '@fortawesome/fontawesome-svg-core';
import Footer from '@components/Footer';

import '@fortawesome/fontawesome-svg-core/styles.css';

// prevent oversizes icons on page load
config.autoAddCss = false;

import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
    faGripLines,
    faTimes,
    faQuestion,
    faBookOpen,
    faUserFriends,
    faBars as faSolidBars,
    faCaretUp,
    faCaretDown,
    faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faLongArrowLeft as faRegularLongArrowLeft,
    faLongArrowRight as faRegularLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
    faGripLines,
    faTimes,
    faFacebook,
    faLinkedin,
    faInstagram,
    faQuestion,
    faBookOpen,
    faUserFriends,
    faCaretDown,
    faSolidBars,
    faCaretUp,
    faCheckCircle,

    faRegularLongArrowLeft,
    faRegularLongArrowRight
);

config.autoAddCss = false;

// wrap your pages in a layout here...
const Layout = ({ children }) => {
    let myLocation = useLocation();

    // Declare const that determines whether Nav is transparent or not. Reads users location and applies if it condition is true.
    const absoluteHeader = myLocation?.pathname === '/' || myLocation?.pathname === '/going-to-uni';

    return (
        <Fragment>
            <div className={styles.app}>
                <SEO />
                <Header absoluteHeader={absoluteHeader} />
                <main className={styles.main}>{children}</main>
                <Footer />
            </div>
        </Fragment>
    );
};

export default Layout;
